import * as React from "react"
// import { Link } from "gatsby"
import Seo from "../components/Seo"

// markup
const NotFoundPage = ({ siteData }) => {
  // const data = useStaticQuery(graphql`
  //   query HeaderQuery {
  //     site {
  //       siteMetadata {
  //         company_name
  //       }
  //     }
  //   }
  // `)
  return (
    <>
      <Seo
        title="Opps - Not Found"
        description={"Page Not Found"}
        siteMetadata={siteData}
      />
      <section className="error-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="error-content">
                <img src="/img/404.png" alt="Page Not Found" />
                <h3>Page Not Found</h3>
                <p>
                  The page you are looking for might have been removed had its
                  name changed or is temporarily unavailable.
                </p>
                <a href="/" className="btn btn-primary">
                  Go to Home <i className="flaticon-next-1"></i>
                </a>
                <div className="mt-4">
                  <img src="/img/logo.png" alt="Nemcare Logo" />
                </div>
                <p
                  style={{ fontSize: 19 }}
                  className={"text-secondary"}
                  style={{
                    marginTop: -15,
                    letterSpacing: 1.5,
                    paddingLeft: 15,
                  }}
                >
                  Nemcare Hospital Tezpur Pvt. Ltd.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NotFoundPage
